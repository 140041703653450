export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/index.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "homepage"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/apps',
    /* internal name: '/apps' */
    /* no component */
    children: [
      {
        path: '',
        name: '/apps/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/apps/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "App"
        }
      },
      {
        path: ':id',
        /* internal name: '/apps/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/apps/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/apps/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "App"
            }
          },
          {
            path: 'models',
            /* internal name: '/apps/[id]/models' */
            /* no component */
            children: [
              {
                path: ':model',
                name: '/apps/[id]/models/[model]',
                component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/apps/[id]/models/[model].vue'),
                /* no children */
                meta: {
                  "action": "read",
                  "subject": "App"
                }
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/brands',
    /* internal name: '/brands' */
    /* no component */
    children: [
      {
        path: '',
        name: '/brands/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/brands/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Brand"
        }
      },
      {
        path: ':id',
        /* internal name: '/brands/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/brands/[id]/',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/brands/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "Brand"
            }
          },
          {
            path: 'edit',
            name: '/brands/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/brands/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Brand"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/brands/new',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/brands/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Retailer"
        }
      }
    ],
  },
  {
    path: '/forgot-password',
    name: '/forgot-password',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/forgot-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/homepage',
    /* internal name: '/homepage' */
    /* no component */
    children: [
      {
        path: '',
        name: '/homepage/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/homepage/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/not-authorized',
    name: '/not-authorized',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/not-authorized.vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/regions',
    /* internal name: '/regions' */
    /* no component */
    children: [
      {
        path: '',
        name: '/regions/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/regions/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Region"
        }
      }
    ],
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "blank",
      "action": "read",
      "subject": "Auth",
      "redirectIfLoggedIn": true
    }
  },
  {
    path: '/retailer-report-deliveries',
    /* internal name: '/retailer-report-deliveries' */
    /* no component */
    children: [
      {
        path: '',
        name: '/retailer-report-deliveries/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailer-report-deliveries/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "RetailerReportDelivery"
        }
      }
    ],
  },
  {
    path: '/retailers',
    /* internal name: '/retailers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/retailers/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Retailer"
        }
      },
      {
        path: ':id',
        /* internal name: '/retailers/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/retailers/[id]/',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "Retailer"
            }
          },
          {
            path: 'consolidation-retail-outlet-data-sources',
            /* internal name: '/retailers/[id]/consolidation-retail-outlet-data-sources' */
            /* no component */
            children: [
              {
                path: ':crodsId',
                /* internal name: '/retailers/[id]/consolidation-retail-outlet-data-sources/[crodsId]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '/retailers/[id]/consolidation-retail-outlet-data-sources/[crodsId]/edit',
                    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/consolidation-retail-outlet-data-sources/[crodsId]/edit.vue'),
                    /* no children */
                    meta: {
                      "action": "update",
                      "subject": "ConsolidationRetailOutletDataSource"
                    }
                  }
                ],
              },
              {
                path: 'new',
                name: '/retailers/[id]/consolidation-retail-outlet-data-sources/new',
                component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/consolidation-retail-outlet-data-sources/new.vue'),
                /* no children */
                meta: {
                  "action": "update",
                  "subject": "ConsolidationRetailOutletDataSource"
                }
              }
            ],
          },
          {
            path: 'edit',
            name: '/retailers/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Retailer"
            }
          },
          {
            path: 'retailer-contacts',
            /* internal name: '/retailers/[id]/retailer-contacts' */
            /* no component */
            children: [
              {
                path: ':rcId',
                /* internal name: '/retailers/[id]/retailer-contacts/[rcId]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '/retailers/[id]/retailer-contacts/[rcId]/edit',
                    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/retailer-contacts/[rcId]/edit.vue'),
                    /* no children */
                    meta: {
                      "action": "update",
                      "subject": "RetailerContact"
                    }
                  }
                ],
              },
              {
                path: 'new',
                name: '/retailers/[id]/retailer-contacts/new',
                component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/retailer-contacts/new.vue'),
                /* no children */
                meta: {
                  "action": "create",
                  "subject": "RetailerContact"
                }
              }
            ],
          },
          {
            path: 'retailer-data-sources',
            /* internal name: '/retailers/[id]/retailer-data-sources' */
            /* no component */
            children: [
              {
                path: ':rdsId',
                /* internal name: '/retailers/[id]/retailer-data-sources/[rdsId]' */
                /* no component */
                children: [
                  {
                    path: 'edit',
                    name: '/retailers/[id]/retailer-data-sources/[rdsId]/edit',
                    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/retailer-data-sources/[rdsId]/edit.vue'),
                    /* no children */
                    meta: {
                      "action": "update",
                      "subject": "RetailerDataSource"
                    }
                  }
                ],
              },
              {
                path: 'new',
                name: '/retailers/[id]/retailer-data-sources/new',
                component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/[id]/retailer-data-sources/new.vue'),
                /* no children */
                meta: {
                  "action": "create",
                  "subject": "RetailerDataSource"
                }
              }
            ],
          }
        ],
      },
      {
        path: 'new',
        name: '/retailers/new',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/retailers/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Retailer"
        }
      }
    ],
  },
  {
    path: '/second-page',
    name: '/second-page',
    component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/second-page.vue'),
    /* no children */
    meta: {
      "action": "read",
      "subject": "SecondPage"
    }
  },
  {
    path: '/tag-monitoring',
    /* internal name: '/tag-monitoring' */
    /* no component */
    children: [
      {
        path: '',
        name: '/tag-monitoring/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/tag-monitoring/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Retailer"
        }
      }
    ],
  },
  {
    path: '/tags',
    /* internal name: '/tags' */
    /* no component */
    children: [
      {
        path: '',
        name: '/tags/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/tags/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "Tag"
        }
      },
      {
        path: ':id',
        /* internal name: '/tags/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/tags/[id]/',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/tags/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "Tag"
            }
          },
          {
            path: 'edit',
            name: '/tags/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/tags/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "Tag"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/tags/new',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/tags/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "Tag"
        }
      }
    ],
  },
  {
    path: '/users',
    /* internal name: '/users' */
    /* no component */
    children: [
      {
        path: '',
        name: '/users/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/users/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "User"
        }
      },
      {
        path: ':id',
        /* internal name: '/users/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/users/[id]/',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/users/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/users/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/users/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "User"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/users/new',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/users/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "User"
        }
      },
      {
        path: 'settings',
        name: '/users/settings',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/users/settings.vue'),
        /* no children */
        meta: {
          "action": "self_edit",
          "subject": "User"
        }
      }
    ],
  },
  {
    path: '/validation-data-sources',
    /* internal name: '/validation-data-sources' */
    /* no component */
    children: [
      {
        path: '',
        name: '/validation-data-sources/',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/validation-data-sources/index.vue'),
        /* no children */
        meta: {
          "action": "read",
          "subject": "ValidationDataSource"
        }
      },
      {
        path: ':id',
        /* internal name: '/validation-data-sources/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/validation-data-sources/[id]/',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/validation-data-sources/[id]/index.vue'),
            /* no children */
            meta: {
              "action": "read",
              "subject": "Retailer"
            }
          },
          {
            path: 'edit',
            name: '/validation-data-sources/[id]/edit',
            component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/validation-data-sources/[id]/edit.vue'),
            /* no children */
            meta: {
              "action": "update",
              "subject": "ValidationDataSource"
            }
          }
        ],
      },
      {
        path: 'new',
        name: '/validation-data-sources/new',
        component: () => import('/home/clic2buy/hub_app/releases/20241007083351/app/frontend/pages/validation-data-sources/new.vue'),
        /* no children */
        meta: {
          "action": "create",
          "subject": "ValidationDataSource"
        }
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

